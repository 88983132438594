import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationService, CheckIn200Response } from 'app/api';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { StorageService } from 'app/services/storage/storage.service';
import { AES } from 'crypto-js';
// import { environment } from 'environments/environment';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;

    private replacableId: string;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private apiService: AuthenticationService,
        private storageSerivce: StorageService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {
        this.replacableId = this.appConfig.basicClientIdToBeReplaced;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        sessionStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return sessionStorage.getItem('access_token') ?? '';
    }

    setAuthenticated(value: boolean) {
        this._authenticated = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Checks in with the given code and company.
     * @param code
     * @param company
     * @returns mandant id
     */
    checkIn(code: string, company: string): Observable<CheckIn200Response> {
        return this.apiService.checkIn({
            code,
            company,
        });
        // .pipe(
        //     tap(response => {
        //         this._userService.id = response.mandant;
        //     })
        // );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.apiService
            .authenticate(this.replacableId, {
                clientId: `${this._userService.checkInId}_id`,
                code: AES.encrypt(
                    `${credentials.email} ${credentials.password}`,
                    'secret'
                ).toString(),
                redirectUri: this.appConfig.host,
            })
            .pipe(
                switchMap((response: any) => {
                    return this.setAccessTokenAndAuthenticated(response.token, true);
                    // this.accessToken = response.token;

                    // this._authenticated = true;

                    // // Store the user on the user service
                    // return this.getUser();
                })
            );
    }

    setAccessTokenAndAuthenticated(accessToken, authenticated): Observable<any> {
        this.accessToken = accessToken;

        this._authenticated = authenticated;

        // Store the user on the user service
        return this.getUser();
    }

    /**
     * Gets the user from the BE using the current accessToken.
     *
     * @returns boolean
     */
    getUser(): Observable<any> {
        return this.apiService.getUser('Bearer ' + this.accessToken).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                if (response) {
                    // TODO deal with avatars
                    //response.avatar = 'assets/images/avatars/male-01.jpg';
                    if (!this._userService.user) {
                        this._userService.user = response;
                    }

                    //Call EmployeeService and set the current user
                    //to use its roles for AI communictaion
                    // this.employeeService.getEmployeeById(this._userService.user);

                    this._authenticated = true;
                    return of(true);
                } else {
                    return of(false);
                }
            })
        );
    }

    /**
     * Sign out
     */
    clearLocalStorage(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('clientId');
        localStorage.removeItem('id');
        localStorage.removeItem('access_token');

        this._userService.user = undefined;

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        const accessToken = localStorage.getItem('access_token');

        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!accessToken) {
            return of(false);
        }

        // Checks if the logged in user can be get from the BE using the stored accessToken
        if (accessToken) {
            return this.getUser();
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return of(false);
    }

    // Roles and permissions

    private getRole(role: string): string {
        return this._userService.user.auth.find((auth: string) => auth.includes(role));
    }
    private hasRole(role: string): boolean {
        return !!this.getRole(role);
    }
    private hasPermissionOnRole(role: string, permission: string): boolean {
        if (this.hasRole(role)) {
            const permissions = this.getRole(role).split('_')[1];
            let permissionsCount = 0;

            for (let index = 0; index < permission.length; index++) {
                const permissionCharacter = permission[index];
                if (permissions.includes(permissionCharacter)) {
                    permissionsCount += 1;
                }
            }
            return permissionsCount === permission.length;
        }
        return false;
    }

    hasPermissionsOnRoles(rolesAndPermissions: string[]): boolean {
        let permissionsOnRolesCount = 0;

        rolesAndPermissions.forEach((roleWithPermissions: string) => {
            const roleAndPermission = roleWithPermissions.split('_');
            if (this.hasPermissionOnRole(roleAndPermission[0], roleAndPermission[1])) {
                permissionsOnRolesCount += 1;
            }
        });

        return permissionsOnRolesCount === rolesAndPermissions.length;
    }
}
